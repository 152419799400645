<template>
  <v-dialog
    v-model="dialog"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          depressed
          @click="closeDialog()"
        >
          Cancelar
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          :loading="isSaving"
          depressed
          @click=" AssignCredit()"
        >
          Asignar Credito
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
          >
            <!-- <v-row v-if="this.$store.getters.usuario.user_rol === 'Encargado'">
              <v-col cols="12">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Fecha"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    locale="es"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="CreditIDToAssign"
                  :disabled="loadingClients"
                  :items="AvailableCredits"
                  :rules="[Validaciones.requerido]"
                  filled
                  chips
                  label="Seleccionar Credito"
                  item-text="description"
                  item-value="id"
                  :loading="loadingClients"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      {{ data.item.description + ' - $' + + data.item.amount }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="(typeof data.item !== 'object')">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.description + ' - $' + + data.item.amount"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="'Cuotas: ' + data.item.quotas + ' - Interes: '+ data.item.interest+ '%'"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="employeeAssign"
                  :disabled="loadingEmployeed"
                  :items="employee"
                  :rules="[Validaciones.requerido]"
                  filled
                  chips
                  label="Seleccionar Cobrador"
                  item-text="name"
                  item-value="personID"
                  :loading="loadingEmployeed"
                  :readonly="disableSelectedEmployee"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      style="color:black; "
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      :disabled="disableSelectedEmployee"
                      @click="data.select"
                      @click:close="removeEmployeed(data.item)"
                    >
                      {{ data.item.name + ' ' + data.item.surname }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="(typeof data.item !== 'object')">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name + ' ' + data.item.surname"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'

import {
  mdiEyeOutline,
} from '@mdi/js'
import { now } from 'moment'

export default {
  props: {
    rol: {
      type: String,
      required: true,
      default: 'Cobrador',
    },
  },
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -1),
      menu: false,
      modal: false,
      dialog: false,
      CreditIDToAssign: null,
      autoUpdate: true,
      employee: [],
      AvailableCredits: [],
      employeeAssign: [],
      disableSelectedEmployee: true,

      icons: {

        mdiEyeOutline,

      },
      loadingButton: false,
      mensajeSnack: '',
      loadingClients: false,
      loadingEmployeed: false,
      isSaving: false,
      client: null,
      Validaciones: {
        requerido: v => !!v || 'Campo Requerido',
      },
    }
  },
  computed: {
    formTitle() {
      return 'Asignar Credito'
    },
    computedDateFormatted() {
      return this.formatDate(this.date)
    },

  },

  methods: {

    openDialog(client) {
      this.client = client
      this.getAvailableCredits()
      this.getEmployeed()
      this.EmployeeVerify()
      this.dialog = true
    },

    EmployeeVerify() {
      if (this.$store.getters.usuario.user_rol === 'Cobrador') {
        this.disableSelectedEmployee = true
        this.employeeAssign = this.$store.getters.usuario.user_personid
      } else {
        this.disableSelectedEmployee = false
        this.employeeAssign = null
      }
    },

    closeDialog() {
      this.dialog = false
    },
    remove() {
      this.CreditIDToAssign = null
      this.data = null
    },
    removeEmployeed() {
      this.employeeAssign = null
      this.data = null
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    submitForm() {
      this.dialog = false
      this.$refs.form.reset()
      this.$emit('Recargar')
    },
    async getAvailableCredits() {
      this.url = `${this.$store.getters.urlBase}/api/Credit`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        url: this.url,
      }
      this.loadingClients = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.AvailableCredits = respuesta.data
        this.loadingClients = false
      } else {
        this.loadingClients = false
      }
    },
    async getEmployeed() {
      this.url = `${this.$store.getters.urlBase}/api/Employee/Collectors`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        url: this.url,
      }
      this.loadingEmployeed = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.employee = respuesta.data
        this.loadingEmployeed = false
      } else {
        this.loadingEmployeed = false
      }
    },
    async AssignCredit() {
      if (this.$refs.form.validate()) {
        this.isSaving = true
        this.url = `${this.$store.getters.urlBase}/api/Client/AssignCredit`
        const params = {
          clientId: this.client.clientId,
          creditId: this.CreditIDToAssign,
          loggedPersonId: this.$store.getters.usuario.user_personid,
          employeePersonId: this.employeeAssign,
          dateAssigned: this.date,
        }

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            // eslint-disable-next-line no-return-assign
            response => {
              this.respuesta = response
              this.isSaving = false
              this.$store.dispatch('showSnack', {
                text: 'Credito Asignado con exito',
                color: 'success',
                timeout: 2500,
              })
              this.$refs.form.reset()
              this.closeDialog()
              this.isSaving = false
            },
          )
          .catch(e => {
            if (e.response) {
              if (e.response.data) { this.mensajeSnack = e.response.data } else { this.mensajeSnack = 'Error en la operación' }
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,

            })
            this.isSaving = false
          })
      }
    },

  },
}
</script>
