var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clientData,"item-key":"id","sort-by":"name","search":_vm.buscar,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"loading":_vm.loadingClients,"loading-text":"Cargando Clientes...","no-data-text":"No hay registros para mostrar.","no-results-text":"No se encontraron registros en la búsqueda"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar por nombre / dni / email / etc.","outlined":"","dense":""},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-spacer'),(_vm.$route.params.id)?_c('v-tooltip',{attrs:{"left":"","color":"second"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((_vm.rol == 'Administrador' || _vm.rol == 'Gerente' || _vm.rol == 'Encargado'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"elevation":"10","small":"","fab":"","color":"second","dark":""},on:{"click":function($event){return _vm.AssignClients()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAccountMultiplePlus)+" ")])],1):_vm._e()]}}],null,false,4106457476)},[_c('span',[_vm._v("Asignar Clientes")])]):_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((_vm.rol == 'Administrador' || _vm.rol == 'Gerente' || _vm.rol == 'Encargado') && _vm.$route.name !== 'clientsassigned')?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"elevation":"10","small":"","fab":"","color":"primary","dark":""},on:{"click":function($event){return _vm.registerClient(0)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAccountPlus)+" ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Agregar Cliente")])])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((_vm.rol == 'Administrador' || _vm.rol == 'Gerente' || _vm.rol == 'Encargado') && _vm.$route.name !== 'clientsassigned')?_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"size":"18"},on:{"click":function($event){return _vm.editClient(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar Cliente")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((_vm.rol == 'Administrador' || _vm.rol == 'Gerente' || _vm.rol == 'Encargado') && _vm.$route.name !== 'clientsassigned')?_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"color":"error","size":"18"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteEmpty)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Eliminar Cliente")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((_vm.rol == 'Administrador' || _vm.rol == 'Gerente' || _vm.rol == 'Encargado' || _vm.rol == 'Cobrador' ) && _vm.$route.name !== 'clientsassigned')?_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"color":"success","size":"18"},on:{"click":function($event){return _vm.$refs.dialogAssignCredit.openDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiCashPlus)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Nuevo Credito")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((_vm.rol == 'Administrador' || _vm.rol == 'Gerente' || _vm.rol == 'Cobrador' || _vm.rol == 'Encargado'))?_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"color":"warning","size":"18"},on:{"click":function($event){return _vm.$refs.dialogCreditHistorial.openDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Historial Crediticio")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((_vm.rol == 'Administrador' || _vm.rol == 'Gerente' || _vm.rol == 'Encargado') && _vm.$route.name === 'clientsassigned')?_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"color":"error","size":"18"},on:{"click":function($event){return _vm.desasignarItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAccountMinus)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Desasociar Cliente")])])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"width":"80%","text-align":"justify"},attrs:{"color":_vm.getColor(item.state),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.state)+" ")])]}}])}),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[(_vm.clienteAEliminar)?_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" ¿Está seguro que desea eliminar a "+_vm._s(_vm.clienteAEliminar.surname)+" "+_vm._s(_vm.clienteAEliminar.name)+"? ")]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" Eliminar ")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialogDesasignar),callback:function ($$v) {_vm.dialogDesasignar=$$v},expression:"dialogDesasignar"}},[_c('v-card',[(_vm.clienteADesasignar)?_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" ¿Está seguro que desea desasociar a "+_vm._s(_vm.clienteADesasignar.surname)+" "+_vm._s(_vm.clienteADesasignar.name)+"? ")]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDesasociar}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.desasociarItemConfirm}},[_vm._v(" Confirmar ")]),_c('v-spacer')],1)],1)],1),_c('dialogAssignClient',{ref:"dialogAssignClient",on:{"assigned":_vm.getClientListOfEmployee}}),_c('dialog-assign-credit',{ref:"dialogAssignCredit"}),_c('dialog-credit-historial',{ref:"dialogCreditHistorial"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }