<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="750"
  >
    <v-container fluid>
      <v-card :loading="getLoading">
        <v-card-title>
          <v-row justify="center">
            <span class="text-h5 center">Historial Crediticio</span>
          </v-row>
        </v-card-title>
        <v-card-text v-if="CreditsAssigned.length > 0">
          <v-timeline>
            <v-timeline-item
              v-for="(credit, i) in CreditsAssigned"
              :key="i"
              color="success"
              small
            >
              <template v-slot:opposite>
                <span
                  :class="`headline font-weight-bold success--text`"
                  v-text="credit.date"
                ></span>
              </template>
              <div class="py-4">
                <h2 class="`headline font-weight-light mb-4 success--text">
                  Solicitud de Crédito
                </h2>
                <div>
                  <span>{{ credit.description }}</span> <br>
                  <span>${{ credit.amount }}</span>
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-text v-else-if="!getLoading">
          <v-row justify="center">
            <span>Sin créditos solicitados</span>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            depressed
            @click="closeDialog()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>
<script>
import axios from 'axios'

import {
  mdiEyeOutline, mdiStar, mdiBookVariant, mdiAirballoon, mdiGateBuffer,
} from '@mdi/js'

export default {
  data() {
    return {
      dialog: false,
      CreditsAssigned: [],
      getLoading: false,
      icons: {
        mdiEyeOutline, mdiStar, mdiBookVariant, mdiAirballoon, mdiGateBuffer,
      },
      items: [
        {
          color: 'red lighten-2',
          icon: mdiStar,
        },
        {
          color: 'purple darken-1',
          icon: mdiBookVariant,
        },
        {
          color: 'green lighten-1',
          icon: mdiAirballoon,
        },
        {
          color: 'indigo',
          icon: mdiGateBuffer,
        },
      ],
      credits: [
        {
          color: 'cyan',
          credit: '1960',
        },
        {
          color: 'success',
          credit: '1970',
        },
        {
          color: 'error',
          credit: '1980',
        },
        {
          color: 'amber',
          credit: '1990',
        },
        {
          color: 'orange',
          credit: '2000',
        },
      ],
    }
  },
  methods: {
    openDialog(client) {
      this.client = client
      this.getAssignedCredits()
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
      this.CreditsAssigned = []
    },
    async getAssignedCredits() {
      this.url = `${this.$store.getters.urlBase}/api/Client/CreditsAssigned`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { clientId: this.client.clientId },
        url: this.url,
      }
      this.getLoading = true
      await axios(this.url, options).then(respuesta => {
        this.CreditsAssigned = respuesta.data
        this.getLoading = false
      }).catch(error => {
        if (error.response.data) {
          this.mensajeSnack = `Error: ${error.response.data}`
        } else if (error.message) {
          this.mensajeSnack = `Error: ${error.message}`
        } else {
          this.mensajeSnack = 'Error de Conexion'
        }
        this.getLoading = false
        this.$store.dispatch('showSnack', {
          text: this.mensajeSnack,
          color: 'error',
          timeout: 2500,
        })
      })
    },
  },
}
</script>
