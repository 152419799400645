<template>
  <v-container fluid>
    <!-- <v-btn color="warning" @click="generarReportePapu">Ver Reporte</v-btn> -->
    <v-data-table
      :headers="headers"
      :items="clientData"
      item-key="id"
      sort-by="name"
      :search="buscar"
      :header-props="headerProps"
      :footer-props="footerProps"
      :loading="loadingClients"
      loading-text="Cargando Clientes..."
      no-data-text="No hay registros para mostrar."
      no-results-text="No se encontraron registros en la búsqueda"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="buscar"
            append-icon="mdi-magnify"
            label="Buscar por nombre / dni / email / etc."
            outlined
            dense
          ></v-text-field>
          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
          <v-spacer></v-spacer>
          <v-tooltip
            v-if="$route.params.id"
            left
            color="second"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="(rol == 'Administrador' || rol == 'Gerente' || rol == 'Encargado')"
                elevation="10"
                small
                fab
                color="second"
                dark
                class="mx-2"
                v-bind="attrs"
                v-on="on"
                @click="AssignClients()"
              >
                <v-icon>
                  {{ icons.mdiAccountMultiplePlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>Asignar Clientes</span>
          </v-tooltip>
          <v-tooltip
            v-else
            left
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="(rol == 'Administrador' || rol == 'Gerente' || rol == 'Encargado') && $route.name !== 'clientsassigned'"
                elevation="10"
                small
                fab
                color="primary"
                dark
                class="mx-2"
                v-bind="attrs"
                v-on="on"
                @click="registerClient(0)"
              >
                <v-icon>
                  {{ icons.mdiAccountPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>Agregar Cliente</span>
          </v-tooltip>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- <v-icon
        size="18"
        class="me-3"
      >
        {{ icons.mdiPencil }}
      </v-icon> -->
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="(rol == 'Administrador' || rol == 'Gerente' || rol == 'Encargado') && $route.name !== 'clientsassigned'"
              size="18"
              class="me-3"
              v-bind="attrs"
              v-on="on"
              @click="editClient(item.id)"
            >
              {{ icons.mdiPencil }}
            </v-icon>
          </template>
          <span>Editar Cliente</span>
        </v-tooltip>

        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="(rol == 'Administrador' || rol == 'Gerente' || rol == 'Encargado') && $route.name !== 'clientsassigned'"
              color="error"
              size="18"
              v-bind="attrs"
              class="me-3"
              v-on="on"
              @click="deleteItem(item)"
            >
              {{ icons.mdiDeleteEmpty }}
            </v-icon>
          </template>
          <span>Eliminar Cliente</span>
        </v-tooltip>

        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="(rol == 'Administrador' || rol == 'Gerente' || rol == 'Encargado' || rol == 'Cobrador' ) && $route.name !== 'clientsassigned'"
              color="success"
              size="18"
              v-bind="attrs"
              class="me-3"
              v-on="on"
              @click="$refs.dialogAssignCredit.openDialog(item)"
            >
              {{ icons.mdiCashPlus }}
            </v-icon>
          </template>
          <span>Nuevo Credito</span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="(rol == 'Administrador' || rol == 'Gerente' || rol == 'Cobrador' || rol == 'Encargado')"
              color="warning"
              size="18"
              v-bind="attrs"
              class="me-3"
              v-on="on"
              @click="$refs.dialogCreditHistorial.openDialog(item)"
            >
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </template>
          <span>Historial Crediticio</span>
        </v-tooltip>

        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="(rol == 'Administrador' || rol == 'Gerente' || rol == 'Encargado') && $route.name === 'clientsassigned'"
              color="error"
              size="18"
              class="me-3"
              v-bind="attrs"
              v-on="on"
              @click="desasignarItem(item)"
            >
              {{ icons.mdiAccountMinus }}
            </v-icon>
          </template>
          <span>Desasociar Cliente</span>
        </v-tooltip>
      </template>
      <template v-slot:item.state="{ item }">
        <v-chip
          :color="getColor(item.state)"
          dark
          small
          style="width:80%; text-align: justify;"
        >
          {{ item.state }}
        </v-chip>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogDelete"
      max-width="800px"
    >
      <v-card>
        <v-card-title
          v-if="clienteAEliminar"
          class="text-h5"
        >
          ¿Está seguro que desea eliminar a {{ clienteAEliminar.surname }} {{ clienteAEliminar.name }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDelete"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            @click="deleteItemConfirm"
          >
            Eliminar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDesasignar"
      max-width="800px"
    >
      <v-card>
        <v-card-title
          v-if="clienteADesasignar"
          class="text-h5"
        >
          ¿Está seguro que desea desasociar a {{ clienteADesasignar.surname }} {{ clienteADesasignar.name }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDesasociar"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="desasociarItemConfirm"
          >
            Confirmar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogAssignClient
      ref="dialogAssignClient"
      @assigned="getClientListOfEmployee"
    ></dialogAssignClient>
    <dialog-assign-credit ref="dialogAssignCredit"></dialog-assign-credit>
    <dialog-credit-historial ref="dialogCreditHistorial"></dialog-credit-historial>
  </v-container>
</template>

<script>
import {
  mdiPencil, mdiDeleteEmpty, mdiAccountPlus, mdiEraser, mdiFaceWoman, mdiFaceMan, mdiAccountMultiplePlus, mdiAccountMinus, mdiCashPlus, mdiEyeOutline,
} from '@mdi/js'
import axios from 'axios'
import dialogAssignClient from '../../employee/assignClients.vue'
import dialogAssignCredit from '../../credit/AssignCreditDialog.vue'
import dialogCreditHistorial from '../../credit/CreditHistorialDialog.vue'

export default {
  components: { dialogAssignClient, dialogAssignCredit, dialogCreditHistorial },
  props: {
    rol: {
      type: String,
      required: true,
      default: 'Cobrador',
    },
    stateClient: {
      type: String,
      required: true,
      default: 'Todo',
    },
  },
  data: () => ({
    dialogDelete: false,
    icons: {
      mdiPencil,
      mdiDeleteEmpty,
      mdiAccountPlus,
      mdiEraser,
      mdiFaceWoman,
      mdiFaceMan,
      mdiAccountMultiplePlus,
      mdiAccountMinus,
      mdiCashPlus,
      mdiEyeOutline,
    },
    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      { text: 'DNI', value: 'dni' },
      // eslint-disable-next-line no-useless-concat
      { text: 'Cliente', value: 'completeClientName' },
      { text: 'Telefono', value: 'phone' },
      { text: 'Situación Crediticia', value: 'state' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    headerProps: {
      sortByText: 'Ordenar por',
    },
    footerProps: {
      itemsPerPageText: 'Registros por página',
      pageText: '{0}-{1} de {2}',
      itemsPerPageOptions: [5, 10, 20, 50, 100, { text: 'Todos', value: -1 }],
    },
    clientData: [],
    buscar: '',
    clienteAEliminar: null,
    loadingClients: false,
    mensajeSnack: '',
    employeePersonId: null,
    dialogDesasignar: false,
    clienteADesasignar: null,
  }),

  // watch: {
  //   dialogDelete(val) {
  //     val || this.closeDelete()
  //   },
  // },
  mounted() {
    if (this.$route.params.id || this.rol === 'Cobrador') { this.getClientListOfEmployee() } else { this.getClientList() }
  },
  methods: {

    deleteItem(item) {
      this.clienteAEliminar = item
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.EliminarClienteConfirmado()
      this.closeDelete()
    },
    desasignarItem(item) {
      this.clienteADesasignar = item
      this.dialogDesasignar = true
    },
    desasociarItemConfirm() {
      this.DesasignarClienteConfirmado()
    },
    closeDesasociar() {
      this.dialogDesasignar = false
      this.clienteADesasignar = null
    },
    closeDelete() {
      this.dialogDelete = false
      this.clienteAEliminar = null
    },
    async EliminarClienteConfirmado() {
      this.url = `${this.$store.getters.urlBase}/api/Client/delete`

      const params = {}
      params.personId = parseInt(this.clienteAEliminar.id)
      params.loggedPersonId = parseInt(this.$store.getters.usuario.user_personid)
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        data: params,
        url: this.url,
      }
      const respuesta = await axios(this.url, options, params)

      // this.url = `${this.$store.getters.urlBase}/api/Client`
      // const options = {
      //   method: 'DELETE',
      //   url: this.url,
      // }
      // const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.$store.dispatch('showSnack', {
          text: 'Cliente eliminado',
          color: 'success',
          timeout: 2500,
        })
      } else {
        if (respuesta.response.data) {
          this.mensajeSnack = `Error: ${respuesta.response.data}`
        } else if (respuesta.message) {
          this.mensajeSnack = `Error: ${respuesta.message}`
        } else {
          this.mensajeSnack = 'Error de Conexion'
        }
        this.$store.dispatch('showSnack', {
          text: this.mensajeSnack,
          color: 'error',
          timeout: 2500,
        })
      }
      this.clienteAEliminar = null
      this.getClientList()
    },
    async DesasignarClienteConfirmado() {
      this.url = `${this.$store.getters.urlBase}/api/Employee/unassignClient`
      const params = {}
      params.employeePersonID = parseInt(this.$route.params.id)
      params.loggedPersonId = parseInt(this.$store.getters.usuario.user_personid)
      params.clientsId = [this.clienteADesasignar.clientId]
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        data: params,
        url: this.url,
      }
      const respuesta = await axios(this.url, options)

      // this.url = `${this.$store.getters.urlBase}/api/Client`
      // const options = {
      //   method: 'DELETE',
      //   url: this.url,
      // }
      // const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.$store.dispatch('showSnack', {
          text: 'Cliente Desasociado',
          color: 'success',
          timeout: 2500,
        })
        this.closeDesasociar()
        this.getClientListOfEmployee()
      } else {
        if (respuesta.response.data) {
          this.mensajeSnack = `Error: ${respuesta.response.data}`
        } else if (respuesta.message) {
          this.mensajeSnack = `Error: ${respuesta.message}`
        } else {
          this.mensajeSnack = 'Error de Conexion'
        }
        this.$store.dispatch('showSnack', {
          text: this.mensajeSnack,
          color: 'error',
          timeout: 2500,
        })
      }
      this.clienteADesasignar = null
      this.getClientListOfEmployee()
    },
    getColor(deuda) {
      if (deuda.trim() === 'Atrasado') return 'warning'
      if (deuda.trim() === 'Con seguimiento especial') return 'danger'
      if (deuda.trim() === 'Con alto riesgo de insolvencia') return 'error'
      if (deuda.trim() === 'Cancelado se renueva') return '#21C9FF'
      if (deuda.trim() === 'Cancelado no se renueva') return '#10A8B8'

      return 'success'
    },
    registerClient(tipoid) {
      // this.$router.push('/registeremployee')
      this.$router.push({ name: 'registerclient', params: { id: tipoid } })
    },
    AssignClients() {
      this.$refs.dialogAssignClient.openDialog(this.$route.params.id)

      // this.$router.push({ name: 'assignclients', params: { id: this.$route.params.id } })
    },
    editClient(tipoid) {
      // this.$router.push('/registeremployee')
      this.$router.push({ name: 'registerclient', params: { id: tipoid } })
    },
    async getClientList() {
      this.url = `${this.$store.getters.urlBase}/api/Client`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',

        },
        params: { clientState: this.stateClient },
        url: this.url,
      }
      this.loadingClients = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.clientData = respuesta.data
        this.loadingClients = false

      // this.getListarDiasMantenimiento();
      } else {
        this.loadingClients = false

      // this.colorSnack = "red";
      // this.mensajeSnack = respuesta.data;
      // this.Showsnackbar = true;
      }
    },
    async getClientListOfEmployee() {
      this.url = `${this.$store.getters.urlBase}/api/Client/ListByEmployee`
      const employeeId = this.$route.params.id ? this.$route.params.id : parseInt(this.$store.getters.usuario.user_personid)
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { employeePersonID: employeeId, clientState: this.stateClient },
        url: this.url,
      }
      this.loadingClients = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.clientData = respuesta.data
        this.loadingClients = false

      // this.getListarDiasMantenimiento();
      } else {
        this.loadingClients = false

      // this.colorSnack = "red";
      // this.mensajeSnack = respuesta.data;
      // this.Showsnackbar = true;
      }
    },
  },
}
</script>
